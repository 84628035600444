<template>
  <el-dialog
    :visible="true"
    width="60%"
  >
    <div slot="title" class="bed-header ">
      图库
    </div>
    <div class="bed-panel">
      <el-tree class="bed-menu" :data="menus" :props="{
          children: 'children',
          label: 'name',
          disabled: 'disabled'
        }">
        <span class="custom-tree-node" slot-scope="{ node}">
          <span class="custom-tree-node-item">{{ node.label }}</span>
        </span>
      </el-tree>
      <div class="bed-images">
        <check-box-group :data="list">
          <template v-slot:default="scope">
            <div style="width: 100px">
              <el-image :src="scope.item.url" fit="contain" style="height: 100px"></el-image>
              <div class="img-name" :title="scope.item.url">{{scope.item.url.replace('https://www.bv-design.cn/api/download/','')}}</div>
            </div>
          </template>
        </check-box-group>
      </div>
    </div>
    <div slot="footer" class="bed-footer">
      <el-pagination
          style="float: right;margin-top: 40px;"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="pageIndex"
          @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import CheckBoxGroup from "@/components/CheckBoxGroup";
import {getImages, getImageType} from "@/api/api";
import {recursiveToTree} from "@/utils/common";
export default {
  name: "ImgBed",
  components: {CheckBoxGroup},
  data(){
    return {
      list:[],
      menus: [],
      pageSize:20,
      pageIndex:1,
      total:0
    }
  },
  mounted() {
   this.refreshData();
    getImageType().then(res => {
      const list = res.data.data
      this.menus = recursiveToTree(list)
      console.log(this.menus)
    })
  },
  methods:{
    pageChange(e) {
      this.pageIndex = e;
      this.refreshData();
    },
    refreshData(){
      getImages({pageSize:this.pageSize,pageIndex:this.pageIndex - 1}).then(res => {
        this.list = res.data.data
        this.total = res.data.cnt
      })
    }
  }
}
</script>

<style scoped>
.bed-panel {
  display: flex;
  width: 100%;
}
.bed-menu {
  width: 15%;
}
.bed-header {
  width: 100%;
  box-sizing: content-box;
  font-size: 24px;
  font-weight: bold;
}
.bed-footer {
  height: 80px;
  width: 100%;
}
.bed-images {
  width: 85%;
  overflow-y: scroll;
  height: 500px;
  border-left: 1px solid #c4c4c4;
  padding: 10px;

}
.img-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node-item {

}
</style>
