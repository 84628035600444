<template>
  <div class='Test'>
      <ImgBed></ImgBed>
  </div>
</template>

<script>
import ImgBed from "@/components/imgBed/ImgBed";
export default {
  name: "Test",
  components: {ImgBed}
}
</script>

<style scoped>
.Test {
  display: flex;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
}
</style>
